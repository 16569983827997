import React from 'react'
import { ThemeProvider } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'

import theme from './src/utils/theme'

export const wrapRootElement = ({ element }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </MuiThemeProvider>
)
